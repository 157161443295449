import React, {useContext, createContext} from 'react';
import type {FC} from 'react';
import {AuthBase, QUERY_PARAMS_TYPE, WithArlLogin} from '@deezer/auth';
import {config} from '@modules/config';

const customFetch: typeof fetch = (url, init = {}) => {
	init.credentials = 'include';
	return globalThis.fetch(url, init);
};

const Auth = WithArlLogin(AuthBase);
const authInstance = new Auth({
	tokenOutputType: QUERY_PARAMS_TYPE.PAYLOAD,
	refreshTokenOutputType: QUERY_PARAMS_TYPE.COOKIE,
	host: config.get('host_authent'),
	customFetch,
});

type AuthProviderValue = {
	invalidateCurrentToken: typeof authInstance.invalidateCurrentToken;
};

const AuthContext = createContext<AuthProviderValue | undefined>(undefined);

const AuthProvider: FC<React.PropsWithChildren<unknown>> = ({children}) => {
	const value = useProviderAuth();
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
export const useAuth = (): AuthProviderValue => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider');
	}
	return context;
};

// Provider hook that creates auth object and handles state
function useProviderAuth(): AuthProviderValue {
	const invalidateCurrentToken = authInstance.invalidateCurrentToken;

	return {invalidateCurrentToken};
}
