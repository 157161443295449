import {config} from '@modules/config';
import {CDP, GateStrategy, CDPSender, MemoryStorage} from '@deezer/logcenter';

const buildDefaultCDP = () => {
	return new CDP(config.get('segmentApiKey'));
};

let cdp: CDP;
export const getCDP = (): CDP => {
	if (!cdp) {
		cdp = buildDefaultCDP();
	}
	return cdp;
};

export const cdpSender = new CDPSender(getCDP());

const memoryStorage = new MemoryStorage();

const cdpConfiguration = {
	handledLogTypes: ['cdp'],
};

export const cdpStrategy = new GateStrategy(
	cdpSender,
	memoryStorage,
	cdpConfiguration,
);
