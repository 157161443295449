import {useEffect} from 'react';
import {cdpStrategy, getCDP} from '@modules/tracking';
import {useUser} from '@deezer/react-user';

export const useInitTracking = () => {
	const [user] = useUser();

	useEffect(() => {
		if (!user?.id) return;
		getCDP().init();
		getCDP().identify({userId: user.id});
		cdpStrategy.openGate();
	}, [user?.id]);
};
