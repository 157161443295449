import dynamic from 'next/dynamic';

const ModalMapping = {
	confirmContestParticipation: dynamic(
		() => import('./content/PurpleClub.Confirmation'),
	),
} as const;

export type ModalKey = keyof typeof ModalMapping;
export type ModalPropsMapping = {
	[K in ModalKey]: React.ComponentProps<(typeof ModalMapping)[K]>;
};

export function getModal(modal: ModalKey) {
	return ModalMapping[modal];
}
